<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col md="12">
        <b-card>
          <h5 v-if="tournament" class="mb-2">{{ tournament.name }}</h5>

          <div class="table-responsive" v-if="tournament">
            <b-table striped hover :items="tournament.players" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" responsive="sm">
              <template #cell(full_name)="data">
                {{ data.value }}
              </template>
              <template #cell(level)="data">
                {{ data.value }}
              </template>
              <template #cell(position)="data">
                {{ formatPosition(data.value) }}
              </template>
            </b-table>
          </div>

        </b-card>
        <a :href="'/tournament/' + this.tournament_id" style="display: block!important;"
          class="float-right d-block btn btn-warning">Volver a torneo
          <!-- <button class="btn btn-warning">Volver a torneo</button> -->
        </a>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { syncRef } from '@vueuse/core';
import { BRow, BCardHeader, BCardTitle, BCardBody, BCol, BCard, BCardText, BLink, BButton, BImg, BMedia, BMediaAside, BAvatar, BMediaBody, BAlert, BTable  } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex';

export default {
  components: { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BLink, BImg, BButton, BMedia, BAvatar, BMediaAside, BMediaBody, BAlert, BTable  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tournament_id: this.$route.params.tournament,
      tournament: null,
      sortBy: 'full_name', // Valor inicial para sortBy
      sortDesc: false,    // Valor inicial para sortDesc
      fields: [
        { key: 'full_name', label: 'Nombre', sortable: true },
        { key: 'level', label: 'Nivel', sortable: true },
        { key: 'position', label: 'Posición', sortable: true },
      ],
    }
  },
  created() {
    this.$http.get("/api/getTournament?id=" + this.tournament_id).then((response) => {
      this.tournament = response.data;
      this.show = false
    })
  },
  methods: {
    formatPosition(position) {
      if (position == 0) return 'Sin especificar';
      if (position == 1) return 'El Revés';
      return 'El Drive';
    },
    // formatTime(date) {
    //   let format_date = new Date(date).toLocaleDateString('es-es', { year: 'numeric', month: '2-digit', day: '2-digit' })
    //   let format_time = new Date(date).toLocaleTimeString('es-es', { hour: '2-digit', minute: '2-digit' });
    //   return format_date + ' - ' + format_time
    // },
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
}
</script>

<style></style>
